export default defineNuxtRouteMiddleware(() => {
    const authStore = useAuthStore()
    if (authStore.isLoggedIn) {
        const tenant = useTenantPinia()
        if (tenant.tenant?.customer && tenant.tenant?.customer.is_banned) {
            return navigateTo({
                name: 'app__banned',
            }, {
                replace: true,
                redirectCode: 302,
            })
        }
    }
})
